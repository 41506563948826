import * as React from "react"
import { graphql } from "gatsby"
import Layout from '../components/layout'
import Seo from '../components/Seo'
import {
  Box,
  Heading,
  Container
} from '@chakra-ui/react'
import SliceZone from "../components/SliceZone/SliceZone"
import { PrismicRichText } from "@prismicio/react"

import SpringBanner from "../components/MountainBanner/SpringBanner/SpringBanner"


const TrecPageTemplate = ({ data, location }) => {
  const document = data.prismicTrecPage.data;
  const pageLocation = location.pathname;

  console.log(document)

  return (
    <Layout pageLocation={pageLocation}>
      <Seo title={document.page_title.text} />
    
      <Heading as="h1" variant="h1" mt="8" mb="0" textAlign="center" color="sage.700">
          {document.page_title.text}
        </Heading>
      
      {document.page_content?.richText[0] && 
      (<Container maxW="container.xl">
        <Box mb="8" p="12">
          <PrismicRichText field={document.page_content.richText} />
        </Box>
      </Container>)
      }

      <SliceZone sliceZone={document.body} />

    </Layout>
  )
}

export const query = graphql`
query TrecPageQuery($uid: String!)
{
  prismicTrecPage(uid: {eq: $uid}) {
    data {
      page_title {
        text
      }
      page_content {
        richText
      }
      body {
        ... on PrismicTrecPageDataBodyNewsCarousel {
          id
          slice_type
          items {
            title
            excerpt
            button_label
            button_link {
              url
              target
            }
            image {
              alt
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    layout: FULL_WIDTH
                    placeholder: BLURRED
                    quality: 8
                 )
                }
              }
            }
          }
        }
        ... on PrismicTrecPageDataBodyLogoCallToAction {
          id
          slice_type
          primary {
            logo {
              alt
              localFile {
                publicURL
              }
            }
            align
            content {
              richText
              text
            }
            button_link {
              url
              target
            }
            button_label
            text_area_text_color
            text_area_background_color
            image_area_background_color
            full_width_image
            button_solid_or_outline
          }
        }
        ... on PrismicTrecPageDataBodyFullImageSection {
          id
          slice_type
          primary {
            text_content {
              richText
            }
            image {
              alt
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: BLURRED)
                }
              }
            }
            headline {
              text
            }
            button_link {
              url
            }
            button_label
            button_color
          }
        }
        ... on PrismicTrecPageDataBodyBlueBoxSection {
          id
          slice_type
          primary {
            section_title {
              text
            }
          }
          items {
            link_3 {
              url
            }
            link_2 {
              url
            }
            link_1 {
              url
            }
            label_3
            label_2
            label_1
            image_3 {
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: BLURRED)
                }
              }
              alt
            }
            image_2 {
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH)
                }
              }
            }
            image_1 {
              alt
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: BLURRED)
                }
              }
            }
          }
        }
        ... on PrismicTrecPageDataBodyAdvertisement {
          id
          slice_type
          primary {
            ad_link {
              url
            }
            desktop_ad_image {
              alt
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: BLURRED)
                }
              }
            }
            mobile_ad_image {
              alt
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: BLURRED)
                }
              }
            }
          }
        }
        ... on PrismicTrecPageDataBodyTabs {
          id
          slice_type
          items {
            tab_title
            tab_content {
              richText
            }
            chamber_member_logos {
              document {
                ... on PrismicLogoGrid {
                  id
                  data {
                    logo_group {
                      logo {
                        alt
                        localFile {
                          childImageSharp {
                            gatsbyImageData(placeholder: BLURRED)
                          }
                        }
                      }
                      link {
                        url
                      }
                    }
                  }
                }
              }
            }
          }
        }
        ... on PrismicTrecPageDataBodyStaffBoardBios {
          slice_type
          primary {
            section_title {
              text
            }
          }
          items {
            title
            photo {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    aspectRatio: 1
                    placeholder: BLURRED
                    transformOptions: {fit: COVER, cropFocus: CENTER }
                    width: 350
                    height: 350
                  )
                }
              }
              alt
            }
            name
            bio {
              richText
            }
          }
        }
        ... on PrismicTrecPageDataBodyRichTextSlice {
          id
          slice_type
          primary {
            image_link {
              url
              target
            }
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
                }
              }
              alt
            }
            content1 {
              richText
            }
          }
        }
        ... on PrismicTrecPageDataBodyAccordion {
          id
          slice_type
          items {
            panel_title
            panel_content {
              richText
            }
          }
        }
      }
    }
  }
}
`


export default TrecPageTemplate;
